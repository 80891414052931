@import "@/assets/scss/style.scss";

.c-locale-picker {
    margin: 16px auto 0;

    &__select {
        width: 300px;
        text-transform: uppercase;
    }

    &__save-button {
        margin-top: 24px;
        display: block;
    }

    .label {
        margin-bottom: 8px;
    }
}
